export const apiURL = process.env.REACT_APP_API_URL;
export const affiliateLink = process.env.REACT_APP_AFFILIATE_LINK + '/api';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.martextrade';

export const SUPPORT_API = {
  ADD_REQUEST: '/user/support/add-request',
  REPLY_TICKET: '/user/support/reply-ticket',
  GET_USER_TICKETS: '/user/support/get-user-tickets',
  GET_USER_TICKET: '/user/support/get-user-ticket/',
  GET_TICKET_FILE: '/user/support/file/',
};

export const AUTHENTICATION = {
  LOGOUT: '/user/auth/logout',
  RESTORE: '/user/auth/restore-password',
  REFRESH_TOKEN: '/user/auth/refresh-token',
  VERIFY_EMAIL: '/user/auth/verify-email',
  REGISTER: '/user/auth/register',
  LOGIN: '/user/auth/login',
  VERIFY_OTP: '/user/auth/verify-otp',
  GOOGLE_LOGIN: '/user/auth/login-google',
  FACEBOOK_LOGIN: '/user/auth/login-facebook',
};

export const PROFILE_API = {
  GET_PROFILE: '/user/profile/get',
  GET_PROFILE_PIC: '/user/profile/profile-picture/1',
  DELETE_PROFILE_PIC: '/user/profile/profile-picture',
  UPDATE_PROFILE: '/user/profile/update',
  DELETE_PROFILE: '/user/auth/delete-account',
};

export const PUBLIC_API = {
  TIMEZONE: '/public/lists/timezones',
  LANGUAGE: '/public/lists/languages',
  CURRENCY: '/public/lists/currencies',
  COUNTRY: '/public/lists/countries',
  TIME: '/public/time',
};

export const LEADER_BOARD_API = {
  GET_USERS: '/user/leaderboard',
};

export const FOREX_PAIRS = {
  LIST: '/user/forex-pair',
  ADD_TO_FAV_LIST: '/user/forex-pair/add-to-favourites',
  FAV_LIST: '/user/forex-pair/favourites',
  REMOVE_FROM_FAV_LIST: '/user/forex-pair/remove-from-favourites',
  FOREX_HISTORY: '/user/forex-pair/history',
};

export const NOTIFICATION = {
  LIST: '/user/notification',
  READ: '/user/notification/read/',
};

export const USER_TRADING = {
  USER_ACCOUNT: '/user/trading-account',
  ACCOUNT_SWITCH: '/user/trading-account/switch',
  RESET_DEMO_BALANCE: '/user/trading-account/reset-demo-balance',
  RESET_LIVE_BALANCE: '/user/trading-account/reset-live-balance',
  TRADE_HISTORY: '/user/trading/trade-history',
  ENTER_TRADE: '/user/trading',
  ACTIVE_TRADE: '/user/trading/active-trades',
  CHANGE_CURRENCY_OVERVIEW: '/user/profile/change-currency-overview',
  PAYMENT_WITHDRAWAL: '/user/payment/withdrawal',
  WITHDRAWAL_LIST: '/user/payment/withdrawals',
  COUNTRY_DOCUMENT_TYPES: '/user/payment/country-document-types',
  EXPORT: '/user/trading/trade-history/export',
};

export const USER_VERIFICATION = {
  DOCUMENT_VERIFICATION: '/user/verification/documents',
};

export const DEPOSIT = {
  PAYMENT_METHOD: '/user/payment/deposit/payment-methods',
  REQUIRED_FIELDS: '/user/payment/deposit/required-fields',
  DEPOSIT: '/user/payment/deposit',
  WITHDRAWAL_BANKS: '/user/payment/withdrawal/banks',
  WITHDRAWAL: '/user/payment/withdrawal',
  CHECK_BONUS_CODE: '/user/payment/check-bonus-code',
  CONFIRM_UPI_PAYMENT: '/user/payment/deposit/confirm',
};

export const TRANSACTIONS = {
  TRANSACTIONS: '/user/payment/transactions',
};

export const WITHDRAWALS = {
  METHODS: '/user/payment/withdrawal/methods',
  WITHDRAWAL_BANKS: '/user/payment/withdrawal/banks',
  REQUIRED_FEILDS: '/user/payment/withdrawal/required-fields',
  MIN_MAX_AMOUNTS: '/user/payment/min-max-amounts',
};

export const CRYPTO = {
  SELECTED_CRYPTO: '/user/payment/cryptocurrency-networks',
};
